import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import Link from 'next/link';
import { ButtonPrimary, ButtonSecondary } from './base/forms/Button';
import Page from './Page';
import {withRouter} from 'next/router';
import {projects} from '../pages/index';
import findIndex from 'lodash/findIndex'
const _propTypes = {
    className: propTypes.string,
    children: propTypes.node,
    onClick: propTypes.func,
    ButtonComponent: propTypes.any,
};

const ProjectNav = global.ProjectNav = class extends React.PureComponent {
    static displayName = 'ProjectNav';

    static propTypes = _propTypes;

    static defaultProps = {
        ButtonComponent: ButtonSecondary,
    };

    render() {
        const { children, router:{route}, ButtonComponent, ...rest } = this.props;
        const index = findIndex(projects,{href:route})
        const nextProject = projects[index+1] || projects[0];
        const {clientImage, href} = nextProject;
        const {clientName} = nextProject;
        return (
          <div className="section section--grey project-nav">
          <div className="container">
                <div className="flex-row mt-4 mb-5 project-bottom-nav justify-content-end">
                    <div className="project-bottom-nav__item">
                        <h3 className="section__title--dark">Next Case Study</h3>
                        <h1>{clientName}</h1>
                        {/*<img src={clientImage} className="img-fluid project-bottom-nav__image mt-3" alt="Client logo"/>*/}
                        <Link prefetch={false} href={href}>
                            <ButtonPrimary className="mt-4">View Project  →</ButtonPrimary>
                        </Link>
                    </div>
                </div>
            </div>
          </div>
        );
    }
};
export default withRouter(ProjectNav);
