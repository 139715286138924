import React, { Children, PureComponent } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import propTypes from 'prop-types';
import cn from 'classnames';
import Page from './Page';
import DarkModeToggle from '../components/DarkModeToggle';

const ActiveLink = withRouter(({ router, children, ...props }) => (
    <Link {...props}>
        {React.cloneElement(Children.only(children), {
            className:
                `/${router.pathname.split('/')[1]}` === props.href ? `${children.props.className} active` : children.props.className,
        })}
    </Link>
));

class Header extends PureComponent {
    static displayName = 'Header';

    static propTypes = {
        className: propTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    toggle = () => {
        const menuOpen = !this.state.menuOpen;
        if (menuOpen) {
            // fade in anim
            gsap.to('.mobile-menu', {
                duration: 0.25,
                opacity: 1,
                display: 'block',
                ease: 'power1',
            });
            gsap.to('.mainmenu', {
                duration: 0.5,
                y: '5%',
                opacity: 1,
                display: 'block',
                ease: 'power1.inOut',
            });
            gsap.from('.pulse-circle', {
                duration: 0.5,
                opacity: 0,
                y: -20,
                stagger: 0.25,
            });
        } else {
            // fade out anim
            gsap.to('.mobile-menu', {
                duration: 0.5,
                opacity: 0,
                display: 'none',
                ease: 'power1',
            });
            gsap.to('.mainmenu', {
                duration: 0.25,
                y: '0%',
                opacity: 0,
                display: 'none',
                ease: 'power1.inOut',
            });
        }
        this.setState({ menuOpen });
    };

    render() {
        return (
            <React.Fragment>

                <div className="sidebar-nav">
                    <div className="sidebar-nav__item-wrapper">

                        <div className="sidebar-nav__item">
                            <Link href="/" prefetch={false}>
                                <h1 className="sidebar-nav__title">Niall Quinn</h1>
                            </Link>
                        </div>

                        {/*
                        <div className="sidebar-nav__item">
                            <span className="sidebar-nav__text">Digital Product & Interaction Designer</span>
                        </div>
                         */}

                        <div className="sidebar-nav__item">

                        </div>
                    </div>

                </div>

                <div className={`frame ${this.props.className || ''}`}>
                    <ActiveLink prefetch={false} href="/">
                     <h1 className="frame__title">Niall Quinn</h1>
                    </ActiveLink>
                    <div className="frame__links">
                        <ActiveLink prefetch={false} href="/about">
                            <a className="link link--kale">About</a>
                        </ActiveLink>
                        <ActiveLink>
                            <a className="link link--kale" href="mailto:niall@niall-quinn.co.uk">
                                Say hello 👋
                            </a>
                        </ActiveLink>
                    </div>
                    <div className="other">
                        <a href="https://github.com/niall-quinn" target="_blank">
                            <svg className="frame__icon" width={29} height={28} viewBox="0 0 29 28">
                                <title>{"github-image"}</title>
                                <path
                                  d="M14.5 0C6.489 0 0 6.428 0 14.355 0 20.7 4.154 26.08 9.914 27.975c.725.135.991-.309.991-.69 0-.34-.012-1.244-.018-2.44-4.033.865-4.884-1.927-4.884-1.927-.66-1.657-1.613-2.1-1.613-2.1-1.314-.89.101-.871.101-.871 1.456.1 2.221 1.479 2.221 1.479 1.293 2.195 3.395 1.56 4.223 1.194.13-.929.504-1.56.919-1.92-3.22-.359-6.605-1.593-6.605-7.093 0-1.567.562-2.848 1.492-3.852-.163-.363-.652-1.823.127-3.8 0 0 1.215-.384 3.988 1.472a14.057 14.057 0 013.625-.484c1.232.007 2.465.165 3.625.484 2.755-1.856 3.969-1.472 3.969-1.472.78 1.977.29 3.437.145 3.8.924 1.004 1.486 2.285 1.486 3.852 0 5.514-3.39 6.728-6.615 7.081.507.431.978 1.311.978 2.656 0 1.921-.018 3.465-.018 3.931 0 .376.254.826.997.682C24.85 26.072 29 20.689 29 14.355 29 6.428 22.508 0 14.5 0z"
                                  fill="#FFF"
                                  fillRule="nonzero"
                                />
                            </svg>

                        </a>
                        <a href="https://twitter.com/niall___quinn" target="_blank">
                            <svg className="frame__icon" width={29} height={24} viewBox="0 0 29 24">
                                <title>{"Path"}</title>
                                <path
                                  d="M26.02 5.822c.018.256.018.511.018.767 0 7.793-5.98 16.772-16.911 16.772-3.367 0-6.496-.967-9.127-2.646.478.054.938.073 1.435.073 2.779 0 5.337-.931 7.38-2.519-2.614-.055-4.804-1.752-5.558-4.088.368.055.736.091 1.122.091.534 0 1.068-.073 1.565-.2-2.724-.548-4.766-2.92-4.766-5.786v-.073a6.023 6.023 0 002.686.748 5.879 5.879 0 01-2.65-4.91c0-1.094.295-2.098.81-2.974A16.94 16.94 0 0014.28 7.246a6.603 6.603 0 01-.147-1.351c0-3.249 2.65-5.895 5.943-5.895 1.712 0 3.257.712 4.343 1.862A11.76 11.76 0 0028.19.438a5.888 5.888 0 01-2.613 3.249A11.992 11.992 0 0029 2.774a12.72 12.72 0 01-2.98 3.048z"
                                  fill="#FFF"
                                  fillRule="nonzero"
                                />
                            </svg>
                        </a>
                        <a href="mailto:niall@niall-quinn.co.uk">
                            <svg className="frame__icon" width={28} height={21} viewBox="0 0 28 21">
                                <title>{"envelope-solid"}</title>
                                <path
                                  d="M27.124 6.847a.325.325 0 01.524.254v11.043a2.593 2.593 0 01-2.592 2.592H2.592A2.593 2.593 0 010 18.144V7.106c0-.27.308-.42.524-.253 1.21.94 2.813 2.133 8.321 6.134 1.14.832 3.062 2.581 4.979 2.57 1.928.017 3.888-1.77 4.984-2.57 5.508-4.001 7.107-5.2 8.316-6.14zm-13.3 6.977c1.253.022 3.056-1.577 3.964-2.236 7.165-5.2 7.71-5.653 9.363-6.95.313-.242.497-.62.497-1.02V2.592A2.593 2.593 0 0025.056 0H2.592A2.593 2.593 0 000 2.592v1.026c0 .4.184.772.497 1.02 1.652 1.291 2.198 1.75 9.363 6.95.908.66 2.711 2.258 3.964 2.236z"
                                  fill="#FFF"
                                  fillRule="nonzero"
                                />
                            </svg>
                        </a>

                    </div>
                    {/*
                    <div className="theme-toggle">
                        <DarkModeToggle/>
                    </div>
                     */}
                </div>

            </React.Fragment>

        );
    }
}

export default withRouter(Header);
