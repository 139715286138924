import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import Header from '../components/Header';
import ProjectHeroPulse from '../components/animation/ProjectHeroPulse';
import Button from "./base/forms/Button";

const _propTypes = {
    className: propTypes.string,
    children: propTypes.node,
    onClick: propTypes.func,
    ButtonComponent: propTypes.any,
};

const ProjectHero = global.ProjectHero = class extends React.PureComponent {
    static displayName = 'ProjectHero';

    static propTypes = _propTypes;

    componentDidMount() {
        gsap.timeline()
            .from('.project-hero .container', {duration: 0.5, y: 10, opacity: 0, ease: 'power1'})
            // .from('.hero__title', {duration: 0.25, x: 10, opacity: 0, ease: 'power1'})
            // .from('.project-hero__image', {duration: 0.25, x: 10, opacity: 0, ease: 'power1'})
            // .from('.project-hero__list-wrapper', {duration: 0.25, y: 10, opacity: 0, ease: 'power1'})
    }

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                <div
                    className={cn(this.props.className, 'case-study__container')}>


                    <div className="row pl-0">
                        <div className="col-md-7">
                            <p className="mb-0">{this.props.ProjectClientName}</p>
                            <h1 className="case-study__title mt-0 mb-0">{this.props.projectTitle}</h1>
                            <p className="mt-0 text-faint">{this.props.projectType}</p>


                            {this.props.appStoreLink ? (
                              <a href={this.props.appStoreLink} target="_blank">
                                  <img src="/static/images/svg-icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                              </a>
                            ) : (
                              null
                            )}

                        </div>
                        <div className="col-md-5 pl-md-5 pl-3">

                            {this.props.projectTeamSize ? (
                              <ul className="list-unstyled team-list">
                                  <li className="text-bold mb-2">Role</li>
                                  {this.props.projectTeamSize}
                              </ul>
                            ) : (
                              null
                            )}

                            <ul className="list-unstyled team-list">
                                <li className="text-bold mb-2">Year</li>
                                {this.props.projectYear}
                            </ul>

                            {/*<ul className="list-unstyled">*/}
                            {/*    <li className="text-bold mb-1">Team size</li>*/}
                            {/*    {this.props.projectTeamSize}*/}
                            {/*</ul>*/}

                            {/*<ul className="list-unstyled">*/}
                            {/*    <li className="text-bold mb-1">Role</li>*/}
                            {/*    <li>UX & UI Designer</li>*/}
                            {/*</ul>*/}

                            {/*<p>1 Product Owner, 2 Designers, 2 Developers</p>*/}

                            {/*1 Product Owner*/}
                            {/*1 Project Manager*/}
                            {/*2 Designers / UI Developers 🙋‍♂*/}
                            {/*2 Backend Developers*/}
                            {/*2 Frontend Developers*/}

                            <ul className="list-unstyled">
                                <li className="text-bold mb-1">Disciplines</li>
                                {this.props.projectDisciplines}
                            </ul>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
};
export default ProjectHero;
