import React from 'react';
import cn from 'classnames';
import filter from 'lodash/filter';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Page from '../components/Page';
import ProjectItem from '../components/ProjectItem';
import { ButtonPrimary } from '../components/base/forms/Button';
import InfiniteScroll from '../components/InfiniteScroll';

export const projects = [
    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/revolv/Revolv_AppStoreVid-iPad_TRIMMED_2.gif',
        clientName: 'Revolv',
        subTitle: 'Augmented Reality, Sound',
        // href: '/projects/revolv',
        title: 'Revolv',
        description: 'Augmented reality mobile app blending graphical sonic data onto the iconic vinyl record turntable.',
        projectImageClass: 'content__item--image-3',
        tags: ['CREATIVE TECHNOLOGY', 'MOBILE'],
        // video: '/static/images/projects/revolv/FluxXR_1.mp4',
        // poster: '/static/images/projects/revolv/1.png'
        projectType: ['UX design, ', 'UI design'],
        //projectRole: 'Co-Creator',
        projectYear: '2022',
        projectDisciplines: 'UX Design, UI Design, Interaction Design, Unity UI Development',
        appStoreLink: 'https://apps.apple.com/gb/app/revolv-ar/id1608008790?platform=iphone'
    },

    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/arcs/arcs_1.gif',
        subTitle: 'Augmented Reality, Sound',
        href: 'https://twitter.com/SignaltoNoise1/status/1282615459448324099',
        title: 'ARCS',
        clientName: 'ARCS',
        projectImageClass: 'content__item--image-1',
        description: 'Augmented Reality, Sound, 3D, Analogue, Hardware, Electromagnetic Sensor, Sound-Reactive, Visual, Shaders, Unity',
        tags: ['CREATIVE TECHNOLOGY'],
        target: "_blank"
        // video: '/static/images/projects/arcs/arcs_2.mp4',
        // poster: '/static/images/projects/arcs/1.jpg'
        // projectType: ['UX design, ', 'UI design']
    },

     //{
     //    ButtonComponent: ButtonPrimary,
     //    projectImage: '/static/images/projects/site-assist/site-assist-2@2x.jpg',
     //    clientName: 'Site Assist',
     //    // href: '/projects/site-assist',
     //    title: 'Site Assist',
     //    description: 'Digitising the construction industry for a sustainable future.',
     //    projectImageClass: 'content__item--image-1',
     //    tags: ['MOBILE', 'WEB'],
     //    // video: '/static/images/projects/revolv/FluxXR_1.mp4',
     //    // poster: '/static/images/projects/revolv/1.png'
     //    // projectType: ['UX design, ', 'UI design'],
     //    projectRole: 'Senior Product Designer',
     //    projectYear: 'Feb 2021 - April 2022',
     //    projectDisciplines: 'UX Design, UI Design, Interaction Design, UI Development',
     //},
     //
     //
     //{
     //    ButtonComponent: ButtonPrimary,
     //    projectImage: '/static/images/projects/detonator/2@2x.png',
     //    clientName: 'Detonator',
     //    // href: '/projects/detonator',
     //    title: 'Detonator',
     //    description: 'Re-introducing reading to a disengaged young audience.',
     //    projectImageClass: 'content__item--image-1',
     //    tags: ['MOBILE'],
     //    projectRole: 'Senior Product Designer',
     //    projectYear: 'Feb - April 2022',
     //    projectDisciplines: 'UX Design, UI Design, Interaction Design',
     //
     //},

    //{
    //    ButtonComponent: ButtonPrimary,
    //    projectImage: '/static/images/projects/tone-and-sculpt/t&s-phones.jpg',
    //    clientName: 'EvolveYou',
    //    subTitle: 'React Native Mobile App',
    //    appStoreLink: "https://apps.apple.com/gb/app/evolveyou/id1446735127",
    //    // href: '/projects/tone-and-sculpt',
    //    title: 'EvolveYou',
    //    description: 'EvolveYou allows you to choose from hundreds of workouts and train at home or in the gym with world renowned personal trainers.',
    //    projectImageClass: 'content__item--image-1',
    //    tags: ['MOBILE'],
    //    projectRole: 'Senior Product Designer',
    //    projectYear: 'Sept 2019 - February 2020',
    //    projectDisciplines: 'UX Design, UI Design, Interaction Design, UI Development',
    //},

    //{
    //    ButtonComponent: ButtonPrimary,
    //    projectImage: '/static/images/projects/hero-wellbeing/phones-flat-mockup-grey.png',
    //    clientImage: '/static/images/clients/electron.png',
    //    clientName: 'Hero Wellbeing',
    //    subTitle: 'React Native Mobile App & Web Application',
    //    // href: '/projects/hero-wellbeing',
    //    title: 'Hero Wellbeing',
    //    description: 'Connected Health, Made Simple.',
    //    projectImageClass: 'content__item--image-1',
    //    tags: ['MOBILE', 'WEB'],
    //    projectRole: 'Senior Product Designer',
    //    projectYear: '2020',
    //    projectDisciplines: 'UX Design, UI Design, Interaction Design, UI Development',
    //    appStoreLink: 'https://apps.apple.com/gb/app/hero-navigator/id1437505158'
    //},

     //{
     //    ButtonComponent: ButtonPrimary,
     //    projectImage: '/static/images/projects/flagsmith/flagsmith-2.jpg',
     //    clientImage: '/static/images/clients/electron.png',
     //    clientName: 'Flagsmith',
     //    subTitle: 'Web Application',
     //    href: '/projects/flagsmith',
     //    title: 'Flagsmith',
     //    description: 'UX & UI Design for a new feature flag platform.',
     //    projectImageClass: 'content__item--image-1',
     //    tags: ['WEB'],
     //    projectRole: 'Senior Product Designer',
     //    projectYear: '2020',
     //    projectDisciplines: 'UX Design, UI Design, Interaction Design, UI Development',
     //},

    // {
    //     ButtonComponent: ButtonPrimary,
    //     projectImage: '/static/images/projects/electron-connect/2@2x.png',
    //     clientName: 'Electron Connect',
    //     href: '/projects/electron-connect',
    //     title: 'Electron Connect',
    //     description: 'UX & UI Design for a first of its kind local energy marketplace.',
    //     projectImageClass: 'content__item--image-1',
    //     tags: ['WEB'],
    //     // video: '/static/images/projects/revolv/FluxXR_1.mp4',
    //     // poster: '/static/images/projects/revolv/1.png'
    //     projectType: ['UX design, ', 'UI design'],
    // },
    // {
    //{
    //    ButtonComponent: ButtonPrimary,
    //    projectImage: '/static/images/projects/electron-trader-dashboard/electron-1.png',
    //    // srcSet: '/static/images/projects/electron-trader/electron-trader-1@1x.png 1x, /static/images/projects/electron-trader/electron-trader-1@2x.png 2x,',
    //    clientImage: '/static/images/clients/electron.png',
    //    clientName: 'Electron TraDER',
    //    subTitle: 'Web Application',
    //    // href: '/projects/electron-trader-dashboard',
    //    title: 'Electron TraDER',
    //    description: 'Tapping into the future of the electricity market. UX & UI Design for a proof of concept local energy marketplace.',
    //    projectImageClass: 'content__item--image-1',
    //    tags: ['WEB'],
    //    projectRole: 'Lead Product Designer',
    //    projectYear: '2020',
    //    projectDisciplines: 'UX Design, UI Design, Interaction Design',
    //},
    //{
    //    ButtonComponent: ButtonPrimary,
    //    projectImage: '/static/images/projects/purely-capital/1.gif',
    //    clientImage: '/static/images/clients/electron.png',
    //    clientName: 'Purely Capital',
    //    subTitle: 'Web Application',
    //    // href: '/projects/purely-capital',
    //    title: 'Purely Capital',
    //    description: 'UX & UI Design for a factoring platform for digital media.',
    //    projectImageClass: 'content__item--image-1',
    //    tags: ['WEB'],
    //    projectRole: 'Senior Product Designer',
    //    projectYear: '2019',
    //    projectDisciplines: 'UX Design, UI Design, Interaction Design, UI Development',
    //},
    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/remap/1.jpg',
        // srcSet: '/static/images/projects/remap/1.jpg 1x, /static/images/projects/projects/remap/1@2x.jpg 2x,',
        clientImage: '/static/images/clients/electron.png',
        subTitle: 'Interactive Sound Installation',
        clientName: 'REMAP',
        href: 'http://www.signal-to-noise.co.uk/portfolio/remap/',
        title: 'REMAP',
        description: 'REMAP (Roland Electro-Mechanical Audio Plotter) is a hybrid sonic drawing device and interactive installation.',
        projectImageClass: 'content__item--image-2',
        tags: ['CREATIVE TECHNOLOGY'],
        target: "_blank",
    },
    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/rio/rio-vid.gif',
        clientName: 'RIO',
        subTitle: 'Interactive Installation',
        href: 'https://www.raspberrypi.org/blog/giant-led-wall/',
        title: 'RIO',
        description: 'Build the wall: a huge wall (of LEDs). Internet Connected LED wall developed at Solid State Group.',
        projectImageClass: 'content__item--image-2',
        tags: ['CREATIVE TECHNOLOGY'],
        target: "_blank"
    },
     //{
     //    ButtonComponent: ButtonPrimary,
     //    projectImage: '/static/images/projects/arcs/arcs-mk2.jpg',
     //    // clientImage: '/static/images/clients/electron.png',
     //    subTitle: 'Augmented Reality, Sound',
     //    href: 'https://twitter.com/SignaltoNoise1/status/1358705384530333696',
     //    title: 'ARCS mk2',
     //    // clientName: 'ARCS mk2',
     //    projectImageClass: 'content__item--image-1',
     //    description: 'Augmented Reality, Sound, 3D, Analogue, Hardware, Electromagnetic Sensor, Sound-Reactive, Visual, Shaders, Unity',
     //    tags: ['CREATIVE TECHNOLOGY'],
     //    target: "_blank"
     //    // video: '/static/images/projects/arcs/arcs_2.mp4',
     //    // poster: '/static/images/projects/arcs/1.jpg'
     //    // projectType: ['UX design, ', 'UI design']
     //},

    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/texture-of-air/2.jpg',
        clientImage: '/static/images/clients/electron.png',
        subTitle: 'Interactive Sound Installation',
        href: 'http://www.signal-to-noise.co.uk/portfolio/thetextureofair/',
        title: 'The Texture of Air',
        description: 'Sonically explorative device developed for The Texture of Air, an art and heritage project which honours two famous London hospitals at a moment of change. The device allows users to engage with and manipulate the project’s extensive sound archives.',
        projectImageClass: 'content__item--image-2',
        tags: ['CREATIVE TECHNOLOGY'],
        target: "_blank"
    },
    {
        ButtonComponent: ButtonPrimary,
        projectImage: '/static/images/projects/sonophore/1.jpg',
        clientImage: '/static/images/clients/electron.png',
        subTitle: 'Interactive Sound Installation',
        clientName: 'Sonophore',
        href: 'http://www.signal-to-noise.co.uk/portfolio/sonophore/',
        title: 'Sonophore',
        description: 'Sonophore is an award winning site-specific, interactive sound installation developed by Signal to Noise. As visitors enter the installation space, they are invited to use a custom wireless glove interface to interact with hundreds of strips of unspooled audio cassette tape.',
        projectImageClass: 'content__item--image-2',
        tags: ['CREATIVE TECHNOLOGY'],
        target: "_blank"
    },
    // {
    //     ButtonComponent: ButtonPrimary,
    //     projectImage: '/static/images/projects/battle-royale/battle_royale_iPhone6Plus-landscape-mockup.jpg',
    //     // srcSet: '/static/images/projects/electron-trader/electron-trader-1@1x.png 1x, /static/images/projects/electron-trader/electron-trader-1@2x.png 2x,',
    //     clientImage: '/static/images/clients/electron.png',
    //     // subTitle: 'Web Application',
    //     subTitle: 'Unity Mobile Game',
    //     clientName: 'Battle Royale',
    //     href: '/projects/battle-royale',
    //     title: 'Battle Royale',
    //     description: 'A competitive survival shooter for mobile',
    //     projectImageClass: 'content__item--image-1',
    //     // blobColour: '#5EABBA',
    //     tags: ['MOBILE'],
    //     // projectType: ['UX design, ', 'UI design'],
    // },
];

const HomePage = class extends React.Component {
    static displayName = 'HomePage';

    constructor(props, context) {
        super(props, context);
        this.state = {
            tab: 'ALL',
        };
    }

    componentDidMount() {
        API.trackPage(Constants.pages.projects);
        gsap.timeline()
        // .from('.content__item', {
        //     duration: 0.25,
        //     y: 10,
        //     opacity: 0,
        //     ease: 'power1',
        // })
        // .from('.project-filter', {
        //     // x: -10,
        //     autoAlpha: 0,
        //     stagger: .15,
        //     ease: 'power1',
        // });
    }

    filterProjects = () => {
        return this.state.tab === 'ALL' ? projects : filter(projects, p => p.tags.includes(this.state.tab));
    };

    render = () => {
        return (
          <Page title={Constants.titles.home} canonical="/">
              <Header/>

              <div data-scroll className="page">

                  <div className="case-study__container page--layout-2">
                      <div className="content content--alternate content--padded">


                          <p className="mb-0">Hello! I’m Niall</p>
                          <h1 className="h1__large">
                              Senior Product Designer working on next-gen listening experiences
                          </h1>


                          {/*

                           <div className="project-filter">
                              <p>Selected projects</p>
                              <ul className="pl-0 list-unstyled mt-0">
                                  <li
                                    onClick={() => this.setState({ tab: 'ALL' })}
                                    className={cn('link link--kale project-filter__nav-item  list-inline-item', { 'project-filter__nav-item--active': this.state.tab === 'ALL' })}
                                  >
                                      All
                                  </li>
                                  <li
                                    onClick={() => this.setState({ tab: 'WEB' })}
                                    className={cn('link link--kale project-filter__nav-item  list-inline-item', { 'project-filter__nav-item--active': this.state.tab === 'WEB' })}
                                  >
                                      Web
                                  </li>
                                  <li
                                    onClick={() => this.setState({ tab: 'MOBILE' })}
                                    className={cn('link link--kale project-filter__nav-item  list-inline-item', { 'project-filter__nav-item--active': this.state.tab === 'MOBILE' })}
                                  >
                                      Mobile
                                  </li>
                                  <li
                                    onClick={() => this.setState({ tab: 'CREATIVE TECHNOLOGY' })}
                                    className={cn('link link--kale project-filter__nav-item  list-inline-item', { 'project-filter__nav-item--active': this.state.tab === 'CREATIVE TECHNOLOGY' })}
                                  >Creative Technology</li>
                              </ul>
                          </div>


                           */}


                          <InfiniteScroll
                            items={this.filterProjects()}
                            chunkSize={1}
                            pageSize={4}
                            renderItem={(p) => (
                              // <React.Fragment>
                              //     <div className="row">
                              //         <div className="col-md-6">
                                      <ProjectItem
                                        key={p.title}
                                        href={p.href}
                                        ButtonComponent={p.ButtonComponent}
                                        projectImage={p.projectImage}
                                        // clientName={p.clientName}
                                        // srcSet={p.srcSet}
                                        subTitle={p.subTitle}
                                        projectImageClass={p.projectImageClass}
                                        // blobColour={p.blobColour}
                                        title={p.title}
                                        // projectType={p.projectType}
                                        clientImage={p.clientImage}
                                        description={p.description}
                                        projectRole={p.projectRole}
                                        projectYear={p.projectYear}
                                        projectDisciplines={p.projectDisciplines}
                                        appStoreLink={p.appStoreLink}
                                        video={p.video}
                                        poster={p.poster}
                                        target={p.target}
                                      />
                              //         </div>
                              //     </div>
                              // </React.Fragment>
                            )}
                          />
                      </div>
                  </div>
              </div>

              <Footer/>
          </Page>

        );
    };
};

export default HomePage;
