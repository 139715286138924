import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import Link from 'next/link';
import ReactPlayer from 'react-player/lazy';
import { ButtonPrimary, ButtonSecondary } from './base/forms/Button';

const _propTypes = {
    className: propTypes.string,
    subtitle: propTypes.string,
    children: propTypes.node,
    onClick: propTypes.func,
    ButtonComponent: propTypes.any,
    projectRole: propTypes.any,
    appStoreLink: propTypes.any,
    projectYear: propTypes.any,
    projectDisciplines: propTypes.any,
};

const ProjectItem = global.ProjectItem = class extends React.PureComponent {
    static displayName = 'ProjectItem';

    static propTypes = _propTypes;

    static defaultProps = {
        ButtonComponent: ButtonSecondary,
    };

    componentDidMount() {
        gsap.to('#path-1 path', {
            duration: 8,
            morphSVG: '#path-2 path',
            yoyo: true,
            repeat: -1,
        });
    }

    render() {
        const { children, ButtonComponent, ...rest } = this.props;
        return (
            <React.Fragment>

                <div className={cn(this.props.projectImageClass, 'content__item content__item--expand')}>
                    <div className="content__item-imgwrap">

                        {this.props.projectImage ? (
                            <div
                              className="content__item-img"
                              style={{
                                  backgroundImage: `url(${this.props.projectImage})`,
                              }}
                            />

                        ) : (
                            null
                        )}

                        {this.props.video ? (
                            <div className="content__item-img no-overflow hidden-sm-down">
                                {/* <ReactPlayer playing={true} loop={true} muted url={this.props.video} /> */}
                                <div className="video-container">
                                    <video
                                      poster={this.props.poster} playsinline disablePictureInPicture
                                      autoPlay muted loop
                                      width="100%" controlsList="nofullscreen"
                                    >
                                        <source
                                          src={this.props.video || null}
                                          type="video/mp4"
                                        />
                                    </video>
                                </div>

                            </div>


                        ) : (
                            null
                        )}

                        {this.props.prototype ? (
                            <div className="content__item-img no-overflow">
                                <iframe
                                  src="https://marvelapp.com/prototype/27h7b6d6?emb=1&iosapp=false&frameless=false"
                                  width="300" height="764" allowTransparency="true"
                                  frameBorder="0"
                                />
                            </div>


                        ) : (
                            null
                        )}


                    </div>
                    <h2 className="content__item-title hidden-sm-down">{this.props.title}</h2>
                    {/* {this.props.subTitle ? ( */}
                    {/*  <span className="content__item-subtitle">{this.props.subTitle}</span> */}

                    {/* ) : ( */}
                    {/*  null */}
                    {/* )} */}
                    {/* {this.props.clientImage ? ( */}
                    {/*  <img */}
                    {/*    src={this.props.clientImage || null} */}
                    {/*    alt="Client brand" className="img-fluid mt-2 project-hero__client-logo d-none" */}
                    {/*  /> */}
                    {/* ) : ( */}
                    {/*  null */}
                    {/* )} */}

                    {/* <div className="content__item-description"> */}
                    {/*     <p> */}
                    {/*         {this.props.description || 'AR Circuit Sniffer is an interactive Augmented\n' + */}
                    {/*         '                        Reality sound installation exploring the invisible electromagnetic fields that are\n' + */}
                    {/*         '                        emitted by the mass of electronic devices that surround us.'} */}
                    {/*     </p> */}


                    {/*     <div className="d-flex project__link"> */}


                    {/*         /!*<Link prefetch={false} href={this.props.href} target={this.props.target}>*!/ */}
                    {/*         /!*    <ButtonPrimary>View Project  →</ButtonPrimary>*!/ */}
                    {/*         /!*</Link>*!/ */}
                    {/*         <a className="link link--kale" href={this.props.href} target={this.props.target}>View more →</a> */}

                    {/*     </div> */}
                    {/*     /!*<p className="content__item-link">*!/ */}
                    {/*     /!*    <a href={this.props.href}>View more →</a>*!/ */}
                    {/*     /!*</p>*!/ */}
                    {/* </div> */}



                    <div className="content__item-description">

                        <h2 className="hidden-md-up">{this.props.title}</h2>
                        {/* <p> */}
                        {/*     {this.props.description || 'AR Circuit Sniffer is an interactive Augmented\n' + */}
                        {/*         '                        Reality sound installation exploring the invisible electromagnetic fields that are\n' + */}
                        {/*         '                        emitted by the mass of electronic devices that surround us.'} */}
                        {/* </p> */}


                        {/* <div className="d-flex project__link"> */}


                        {/*     /!* <Link prefetch={false} href={this.props.href} target={this.props.target}> *!/ */}
                        {/*     /!*    <ButtonPrimary>View Project  →</ButtonPrimary> *!/ */}
                        {/*     /!* </Link> *!/ */}
                        {/*     /!* <a className="link link--kale" href={this.props.href} target={this.props.target}>View more →</a> *!/ */}

                        {/* </div> */}
                        <div className="row flex-column height-100 m-0">
                            <div className="d-block col p-0">
                                <p className="mt-0">
                                    {this.props.description || 'AR Circuit Sniffer is an interactive Augmented\n'
                                        + '                        Reality sound installation exploring the invisible electromagnetic fields that are\n'
                                        + '                        emitted by the mass of electronic devices that surround us.'}
                                </p>

                                {this.props.href ? (
                                    <a className="link link--kale mb-5" href={this.props.href} target={this.props.target}>View more →</a>
                                ) : (
                                    null
                                )}


                                {this.props.appStoreLink ? (
                                    <a href={this.props.appStoreLink} target="_blank" className="d-block d-lg-inline">
                                        <img className="mt-4" src="/static/images/svg-icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                                    </a>
                                ) : (
                                    null
                                )}


                            </div>
                            <div className="d-flex col p-0 height-100 align-items-end">
                                <div>

                                    {this.props.projectRole ? (
                                        <>
                                            <p className="text-bold mb-0">Role</p>
                                            <p className="mt-0">{this.props.projectRole}</p>
                                        </>
                                    ) : (
                                        null
                                    )}

                                    {this.props.projectYear ? (
                                            <>
                                                <p className="text-bold mb-0">Year</p>
                                                <p className="mt-0">{this.props.projectYear}</p>
                                            </>
                                    ) : (
                                        null
                                    )}

                                    {this.props.projectDisciplines ? (
                                            <>
                                                <p className="text-bold mb-0">Disciplines</p>
                                                <p className="mt-0">{this.props.projectDisciplines}</p>
                                            </>
                                    ) : (
                                        null
                                    )}


                                </div>
                                {/* <Link prefetch={false} href={this.props.href} target={this.props.target}> */}
                                {/*    <ButtonPrimary>View Project  →</ButtonPrimary> */}
                                {/* </Link> */}
                                {/* <a className="link link--kale" href={this.props.href} target={this.props.target}>View more →</a> */}
                            </div>
                        </div>

                    </div>


                </div>


            </React.Fragment>


        );
    }
};
export default ProjectItem;
